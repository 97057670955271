import { gql } from '@apollo/client';

export const GET_ENTITY = gql`
  query getEntity($entity: ID!) {
    getEntity(entity: $entity) {
      id
      displayName
      firstname
      lastname
      type
      businessname
      identification
      email
      phone
      address
      country
      location
      city
      departament
      postalCode
      description
      customFields {
        id
        name
        studioId
        type
        modelId
        modelType
        value
      }
      tags {
        id
        name
        studioId
      }
      state {
        id
        name
        color
      }
    }
  }
`;

export const GET_ENTITIES = gql`
  query entities(
    $studio: ID!
    $search: String
    $type: EntityType
    $tags: [String!]
  ) {
    entities(studio: $studio, search: $search, type: $type, tags: $tags) {
      id
      type
      displayName
      type
      email
      phone
      state {
        id
        name
        color
      }
      tags {
        id
        name
        studioId
      }
    }
  }
`;

export const GET_ENTITY_SINGLE_PAGE_METADATA = gql`
  query getEntitySinglePage($entity: ID!) {
    getEntity(entity: $entity) {
      id
      displayName
      firstname
      lastname
      type
      businessname
      identification
      email
      phone
      address
      country
      location
      city
      departament
      postalCode
      description

      state {
        id
        color
        studioId
        name
      }

      tags {
        studioId
        name
      }

      filecases {
        id
        title
        externalId
        updatedAt
        description
        stateUpdatedAt
        latestEntry {
          id
          content
          date
          createdAt
          updatedAt
          createdBy {
            id
            picture
            name
          }
        }
        state {
          id
          color
          name
        }
        tags {
          name
          studioId
        }
        assigned {
          id
          picture
          name
        }
      }

      sales {
        id
        date
        amount
        concept
        charged
        currency {
          id
          name
          studioId
        }
      }

      expenses {
        id
        date
        amount
        concept
        paid
        currency {
          id
          name
          studioId
        }
      }

      comments {
        id
        content
        updatedAt
        createdAt
        createdBy {
          id
          name
          picture
        }
      }

      documents {
        id
        size
        kind
        isFolder
        extension
        mimetype
        name
        url
      }

      events {
        id
        calendarId
        type
        title
        description
        state
        start
        end
        assigned {
          id
          name
          picture
        }

        calendar {
          id
          name
          color
        }

        todos {
          calendarId
          eventId
          id
          done
          title
        }
      }
    }
  }
`;

export const GET_COMMENT_RESPONSES = gql`
  query getCommentResponses($comment: ID!) {
    commentResponses(comment: $comment) {
      content
      createdAt
      createdBy {
        picture
        name
      }
    }
  }
`;

export const GET_PAGINATED_ENTITIES = gql`
  query paginatedEntities(
    $studio: ID!
    $search: String
    $type: EntityType
    $tags: [String!]
    $limit: Int
    $offset: Int
    $orderBy: EntitiesOrderBy
    $sortOrder: SortOrder
    $entityState: ID
  ) {
    paginatedEntities(
      studio: $studio
      search: $search
      type: $type
      tags: $tags
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      sortOrder: $sortOrder
      entityState: $entityState
    ) {
      count
      data {
        id
        type
        displayName
        identification
        type
        email
        phone
        state {
          id
          color
          name
        }
        tags {
          name
          studioId
        }
      }
    }
  }
`;

export default null;
