import { gql } from '@apollo/client';

export const GET_FILECASE_SINGLE_DATA = gql`
  query filecaseSingleData($filecase: ID!) {
    filecase(filecase: $filecase) {
      id
      title
      externalId
      description
      createdAt
      updatedAt
      totalHours

      documents {
        id
        name
        extension
        mimetype
        url
        isFolder
        size
        kind
      }

      customFields {
        id
        name
        studioId
        type
        value
        modelId
        modelType
      }

      latestEntry {
        id
        content
        date
        createdAt
        updatedAt
        createdBy {
          id
          picture
          name
        }
      }

      chargedHours {
        id
        amount
        hours
        minutes
        concept
        createdAt
        createdBy {
          id
          name
          picture
        }
        assignedTo {
          id
          name
          picture
        }
      }

      category {
        id
        name
      }
      state {
        id
        color
        name
      }
      side {
        id
        name
      }
      tags {
        id
        name
        studioId
        updatedAt
      }
      createdBy {
        id
        email
        name
        picture
      }
      entries {
        id
        date
        content
        createdAt
        updatedAt

        createdBy {
          id
          picture
          name
        }
      }

      canBeSeenBy {
        id
        name
        email
        picture
      }

      sales {
        id
        date
        amount
        concept
        charged
        entity {
          id
          displayName
          type
          phone
        }
        currency {
          id
          name
          studioId
        }
      }

      expenses {
        id
        date
        amount
        concept
        paid
        entity {
          id
          displayName
          type
          phone
        }
        responsible {
          id
          picture
          name
        }
        currency {
          id
          name
          studioId
        }
      }

      events {
        id
        calendarId
        type
        title
        start
        end
        state
        allDay
        description
        calendar {
          id
          name
          color
        }
        todos {
          calendarId
          eventId
          id
          done
          title
        }
        assigned {
          id
          name
          picture
        }
      }

      entities {
        id
        displayName
        type
        phone
        email
        identification
        country
        address

        customFields {
          id
          name
          studioId
          type
          value
          modelId
          modelType
        }

        state {
          id
          name
          color
        }
      }

      assigned {
        id
        name
        picture
      }

      favorites {
        id
      }
    }
  }
`;

export const GET_FILECASES_WITH_FILTERS = gql`
  query filecasesWithFilters(
    $studio: ID!
    $search: String
    $category: String
    $state: String
    $side: String
    $tags: [String!]
    $entity: String
  ) {
    tags(studio: $studio) {
      id
      name
    }

    categories(studio: $studio) {
      id
      name
    }

    sides(studio: $studio) {
      id
      name
    }

    states(studio: $studio) {
      id
      color
      name
    }

    entities(studio: $studio) {
      id
      displayName
    }

    filecases(
      studio: $studio
      search: $search
      category: $category
      state: $state
      side: $side
      tags: $tags
      entities: $entities
    ) {
      id
      title
      externalId
      category {
        name
      }
      state {
        id
        name
      }
      tags {
        id
        name
        studioId
      }
      createdBy {
        id
        email
      }
      updatedAt
    }
  }
`;

export const GET_FILTERS = gql`
  query getFilters($studio: ID!) {
    tags(studio: $studio) {
      name
    }

    categories(studio: $studio) {
      id
      name
    }

    sides(studio: $studio) {
      id
      name
    }

    states(studio: $studio) {
      id
      name
    }

    entities(studio: $studio) {
      id
      type
      displayName
    }

    members(studio: $studio) {
      id
      name
    }
  }
`;

export const GET_REPORTS_FILECASES = gql`
  query reportfilecase(
    $studio: ID!
    $start: DateTime
    $end: DateTime
    $assigned: [ID!]
    $entities: [String]
    $category: String
    $tags: [String!]
    $side: String
    $state: String
  ) {
    filecases(
      studio: $studio
      start: $start
      end: $end
      assigned: $assigned
      entities: $entities
      category: $category
      tags: $tags
      side: $side
      state: $state
    ) {
      id
      title
      externalId
      description
      category {
        id
        name
      }
      state {
        id
        name
        color
      }

      tags {
        id
        name
        studioId
      }
      assigned {
        id
        picture
        name
      }
      entities {
        id
        type
        displayName
      }
    }
  }
`;

export const GET_PAGINATED_FILECASES = gql`
  query paginatedFilecases(
    $search: String
    $category: String
    $state: String
    $entities: [String!]
    $assigned: [ID!]
    $tags: [String!]
    $limit: Int
    $offset: Int
    $studio: ID!
    $onlyFavorites: Boolean
    $orderBy: PaginatedFilecasesSortBy
    $sortOrder: SortOrder
  ) {
    paginatedFilecases(
      search: $search
      category: $category
      state: $state
      entities: $entities
      assigned: $assigned
      tags: $tags
      limit: $limit
      offset: $offset
      studio: $studio
      onlyFavorites: $onlyFavorites
      orderBy: $orderBy
      sortOrder: $sortOrder
    ) {
      count
      data {
        id
        title
        externalId
        updatedAt
        description
        stateUpdatedAt
        createdAt
        updatedAt
        latestEntry {
          id
          content
          date
          createdAt
          updatedAt
          createdBy {
            id
            picture
            name
          }
        }
        createdAt
        category {
          id
          name
        }
        state {
          id
          color
          name
        }
        side {
          id
          name
        }
        tags {
          name
          studioId
        }
        createdBy {
          id
          email
        }
        assigned {
          id
          picture
          name
        }
        entities {
          id
          type
          displayName
          phone
        }
        favorites {
          id
        }
      }
    }
  }
`;
